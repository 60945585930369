import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { TopbarComponent } from './topbar/topbar.component';
import { LayoutsComponent } from './layouts.component';
import { FooterComponent } from './footer/footer.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faSquare, faCheckSquare, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCheckSquare as farCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { TopbardetailComponent } from './topbardetail/topbardetail.component';
import { Daterangepicker } from 'ng2-daterangepicker';


@NgModule({
  declarations: [TopbarComponent, LayoutsComponent, FooterComponent, TopbardetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule,
    Daterangepicker,
    ReactiveFormsModule
  ],
  exports: [LayoutsComponent, TopbarComponent, FooterComponent]
})
export class LayoutsModule {constructor(private library: FaIconLibrary) {
  library.addIcons(faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faSquare, faCheckSquare, farSquare, farCheckSquare, faStackOverflow, faGithub, faMedium, faGoogle, faCalendarDay);
}}