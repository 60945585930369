import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LandaService } from './../../core/services/landa.service';
import { Router, NavigationEnd, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
  styles: [`
    .pointer-hover {
      cursor: pointer
    }
  `]
})
export class TopbarComponent implements OnInit {
  @Input() perusahaan
  @Output() valueChange = new EventEmitter()
  counter = 0
  constructor(
  ) { }

  ngOnInit(): void {
  }
  valueChanged(){
    console.log(this.perusahaan)
    this.counter = this.counter + 1
    this.valueChange.emit(this.counter)
  }
  open(){

  }
}