<nav class="navbar navbar-expand-lg navbar-dark bg-footer shadow-top" style="position: fixed;
bottom: 0;
max-width: 576px;
z-index: 1000;
padding: 0;">
    <table style="width: 100%">
        <tr>
            <td class="text-center" (click)="pindahMenu('home')" [ngClass]="jenisView == 'home' ? 'bg-biru' : ''"
                style="cursor: pointer; width: 50%; border-right: 0.5px solid #c4c4c4; padding:10px; padding-top: 15px;">
                <span>
                    <h6 style="margin: 0px; font-weight: bold;">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" style="margin-bottom: 5px;">
                            <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/>
                        </svg>
                        Home
                    </h6>
                </span>
            </td>
            <td class="text-center" (click)="pindahMenu('report')" [ngClass]="jenisView == 'report' ? 'bg-biru' : ''"
                style="cursor: pointer; width: 50%; padding:10px; padding-top: 15px;">
                <span>
                    <h6 style="margin: 0px; font-weight: bold;">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" style="margin-bottom: 5px;">
                            <path d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"/>
                        </svg>
                        Report
                    </h6>
                </span>
            </td>
        </tr>
    </table>
    <!-- <div class="container d-flex justify-content-between">
        <div class="text-info px-4 py-2" (click)="prevMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true ? 'd-none' : ''" [icon]="['fas', 'angle-left']" style="font-size: large;"></fa-icon>
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && !isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ thisMonth }} {{ thisYear }}
        </div>
        <div class="text-info" style="font-size: large;" *ngIf="!isPickTgl && isPeriode" (click)="pilihTgl()">
            <fa-icon [icon]="['fas', 'calendar-day']" style="font-size: large;" class="mr-2"></fa-icon>
            {{ periodeStart | date: "dd MMM yyyy" }} - {{ periodeEnd | date: "dd MMM yyyy" }}
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" (click)="resetPeriodeFooter()" class="mb-1 ml-2">
                <style>svg{fill:#009aac}</style>
                <path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/>
            </svg>
        </div>
        <input class="form-control" [ngClass]="isPickTgl == false ? 'd-none' : ''" type="text" name="daterangeInput" 
                daterangepicker [options]="options" (selected)="selectedDate($event, daterange)" (cancelDaterangepicker)="cancelPickTgl()"/>
        <div class="text-info px-4 py-2" (click)="nextMonth()" role="button">
            <fa-icon  [ngClass]="isPeriode == true ? 'd-none' : ''" [icon]="['fas', 'angle-right']" style="font-size: large;"></fa-icon>
        </div>
    </div> -->
</nav>