import { DashboardComponent } from './../pages/dashboard/dashboard.component';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css']
})
export class LayoutsComponent implements OnInit {

  constructor(private dashboardComponent: DashboardComponent) { }

  ngOnInit(): void {
  }

}
