import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  styles: [`
    .pointer-hover {
      cursor: pointer
    }
  `]
})
export class AppComponent {
  title = 'Venturo';
  bulan: any
  constructor() {
    let d = new Date()
    this.bulan = d.getMonth()
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
}