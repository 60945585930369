import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() perusahaan
  @Input() thisYear
  @Input() val
  @Input() isLoading
  @Output() paramsprev = new EventEmitter()
  @Output() paramsnext = new EventEmitter()
  @Output() paramsperiode = new EventEmitter()
  @Output() resetperiode = new EventEmitter()
  @Output() pindahmenu = new EventEmitter()
  month = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ]  
  thisMonth: any
  current: any
  isPickTgl: any
  isPeriode: any
  periodeStart: any
  periodeEnd: any
  jenisView: any
  constructor() {
  }

  public daterange: any = {};
 
  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  options: any = {
    drops: 'up',
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };
 
  selectedDate(value: any, datepicker?: any) { 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
 
    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;

    this.periodeStart = value.start
    this.periodeEnd = value.end

    this.isPickTgl = false;
    this.isPeriode = true;
    
    this.pilihPeriode()
  }
  
  ngOnInit(): void {
    this.current = 0
    this.thisMonth = this.month[0]
    let d = new Date()
    this.current = d.getMonth()
    this.thisMonth = this.month[this.current]
    this.thisYear = d.getFullYear()
    this.isPickTgl = false    
    this.jenisView = 'home'
  }

  pilihTgl() {
    this.daterange.start = this.periodeStart;
    this.daterange.end = this.periodeEnd;

    this.isPickTgl = true;
  }
  cancelPickTgl() {
    this.isPickTgl = false;
  }

  nextMonth() {
    if (this.isLoading == false) {
      this.current = this.current == 11 ? 0 : ++this.current
      this.thisYear = this.current == 0 ? ++this.thisYear : this.thisYear
      this.thisMonth = this.month[this.current]
      let params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear
      }
      this.paramsnext.emit(params)
    }
  }
  prevMonth() {
    if (this.isLoading == false) {
      this.current = this.current == 0 ? 11 : --this.current
      this.thisYear = this.current == 11 ? --this.thisYear : this.thisYear
      this.thisMonth = this.month[this.current]
      let params = {
        key: this.val.key,
        bulan: this.current + 1,
        tahun: this.thisYear
      }
      this.paramsprev.emit(params)
    }
  }
  pilihPeriode() {
    var start = new Date(this.periodeStart);
    var end = new Date(this.periodeEnd);

    let params = {
      key: this.val.key,
      is_periode: 1,
      start: start.getDate() + '-' + (start.getMonth() + 1) + '-' + start.getFullYear(),
      end: end.getDate() + '-' + (end.getMonth() + 1) + '-' + end.getFullYear()
    }
    
    this.paramsperiode.emit(params)
  }
  resetPeriodeFooter() {    
    this.isPeriode = false
    this.resetperiode.emit()
  }
  pindahMenu(param) {
    this.jenisView = param
    this.pindahmenu.emit(param)
  }
}
