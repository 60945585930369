import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortname'
})
export class SortnamePipe implements PipeTransform {

  transform(value: any, args?: any[]): any {
    let arr = []

    value.forEach((item, index) => {
      arr.push(item.value)
    })
    arr.sort((a,b) => (a.akun > b.akun) ? 1 : ((b.akun > a.akun) ? -1 : 0))
    console.log({
      array: arr,
      value: value
    })
    return arr
    // value.sort((a, b) => {
    //   let x = a.name.toLowerCase()
    //   let y = b.name.toLowerCase()
    //   if (x < y) {
    //     return -1
    //   } else {
    //     return 1
    //   }
    //   return 0
    // })
  }
}
