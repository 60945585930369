// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var api = 'https://acc-api.landa.co.id';
var img = 'https://acc-api.landa.co.id/assets/img/';
if (window.location.hostname == 'localhost') {
    api = window.location.port == '81' ? 'http://localhost:81/api' : 'http://localhost/acc-venturo/api';
    img = window.location.port == '81' ? 'http://localhost:81/api/assets/img/' : 'http://localhost/acc-venturo/api/assets/img/';
} else
    if (window.location.hostname == '192.168.10.114') {
        api = 'http://192.168.10.114/venturo/api';
        img = 'http://192.168.10.114/venturo/api/assets/img/';
    }

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyDPtnERtJfy7iNCmoT661-rRgkrRdfyDPY',
        authDomain: 'humanis-2020.firebaseapp.com',
        databaseURL: 'https://humanis-2020.firebaseio.com',
        projectId: 'humanis-2020',
        storageBucket: 'humanis-2020.appspot.com',
        messagingSenderId: '426951655222',
        appId: '1:426951655222:web:5373aa328f1a45ca6d3d97',
        measurementId: 'G-B9XTTKCW55'
    },

    // apiURL: 'http://localhost/atina/atina-api',
    apiURL: api,
    // imageURL: 'http://localhost/atina/atina-api/assets/img/',
    imageURL: img,


};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

